import React from "react";

const EyeIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="17" height="17" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6875 8.49998C11.6875 10.2604 10.2604 11.6875 8.5 11.6875C6.73959 11.6875 5.3125 10.2604 5.3125 8.49998C5.3125 6.73957 6.73959 5.31248 8.5 5.31248C10.2604 5.31248 11.6875 6.73957 11.6875 8.49998ZM10.2708 8.49998C10.2708 9.47798 9.478 10.2708 8.5 10.2708C7.522 10.2708 6.72917 9.47798 6.72917 8.49998C6.72917 7.52198 7.522 6.72915 8.5 6.72915C9.478 6.72915 10.2708 7.52198 10.2708 8.49998Z"
        fill="#6B7684"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 14.1666C12.75 14.1666 15.9375 10.2708 17 8.3229C15.7604 6.49304 12.75 2.83331 8.5 2.83331C4.25 2.83331 1.23958 6.49304 0 8.3229C1.0625 10.2708 4.25 14.1666 8.5 14.1666ZM3.96162 10.8869C2.99434 10.0809 2.2216 9.152 1.70031 8.3853C2.2738 7.63528 3.05181 6.75667 4.00261 6.00091C5.28573 4.98099 6.7984 4.24998 8.5 4.24998C10.2016 4.24998 11.7143 4.98099 12.9974 6.00091C13.9482 6.75667 14.7262 7.63528 15.2997 8.3853C14.7784 9.152 14.0057 10.0809 13.0384 10.8869C11.7291 11.978 10.1805 12.75 8.5 12.75C6.81945 12.75 5.27094 11.978 3.96162 10.8869Z"
        fill="#6B7684"
      />
    </svg>
  );
};

export default EyeIcon;
