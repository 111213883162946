import React from "react";

const PostTrashIcon = ({ size }) => {
  return (
    <>
      {size === "small" ? (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4141 3.21788H10.4258L10.1917 2.75664C10.1421 2.65803 10.0657 2.57509 9.97114 2.51713C9.87656 2.45918 9.76752 2.42851 9.6563 2.42859H6.80996C6.69899 2.42817 6.59015 2.45872 6.49589 2.51674C6.40164 2.57476 6.3258 2.6579 6.27705 2.75664L6.04297 3.21788H3.05469C2.94902 3.21788 2.84767 3.25946 2.77295 3.33347C2.69823 3.40748 2.65625 3.50786 2.65625 3.61252V4.40181C2.65625 4.50647 2.69823 4.60685 2.77295 4.68086C2.84767 4.75487 2.94902 4.79645 3.05469 4.79645H13.4141C13.5197 4.79645 13.6211 4.75487 13.6958 4.68086C13.7705 4.60685 13.8125 4.50647 13.8125 4.40181V3.61252C13.8125 3.50786 13.7705 3.40748 13.6958 3.33347C13.6211 3.25946 13.5197 3.21788 13.4141 3.21788V3.21788ZM3.98105 13.9472C4.00006 14.2478 4.134 14.5299 4.3556 14.7361C4.5772 14.9423 4.86982 15.0571 5.17388 15.0572H11.2949C11.5989 15.0571 11.8915 14.9423 12.1131 14.7361C12.3348 14.5299 12.4687 14.2478 12.4877 13.9472L13.0156 5.58573H3.45312L3.98105 13.9472Z"
            fill="#6B7684"
          />
        </svg>
      ) : (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5703 3.97501H12.8789L12.5897 3.40524C12.5285 3.28343 12.4341 3.18097 12.3173 3.10938C12.2005 3.03778 12.0658 2.99991 11.9284 3.00001H8.4123C8.27522 2.99948 8.14077 3.03722 8.02434 3.10889C7.90791 3.18056 7.81422 3.28327 7.754 3.40524L7.46484 3.97501H3.77344C3.6429 3.97501 3.51771 4.02637 3.42541 4.11779C3.33311 4.20921 3.28125 4.33321 3.28125 4.4625V5.4375C3.28125 5.5668 3.33311 5.69079 3.42541 5.78222C3.51771 5.87364 3.6429 5.925 3.77344 5.925H16.5703C16.7008 5.925 16.826 5.87364 16.9183 5.78222C17.0106 5.69079 17.0625 5.5668 17.0625 5.4375V4.4625C17.0625 4.33321 17.0106 4.20921 16.9183 4.11779C16.826 4.02637 16.7008 3.97501 16.5703 3.97501V3.97501ZM4.91777 17.2289C4.94125 17.6002 5.1067 17.9487 5.38045 18.2034C5.65419 18.4581 6.01566 18.6 6.39126 18.6H13.9525C14.3281 18.6 14.6896 18.4581 14.9633 18.2034C15.237 17.9487 15.4025 17.6002 15.426 17.2289L16.0781 6.9H4.26562L4.91777 17.2289Z"
            fill="#6B7684"
          />
        </svg>
      )}
    </>
  );
};

export default PostTrashIcon;
