export const fontSizes = {
  titleXxl: "5.6rem",
  titleXl: "4.8rem",
  titleL: "3.6rem",
  titleM: "3.2rem",
  titleS: "2.4rem",
  textXxl: "2rem",
  textXl: "1.8rem",
  textL: "1.6rem",
  textM: "1.4rem",
  textS: "1.2rem",
};
//폰트 크기 에셋입니다.
