import React from "react";

const PostThumbnail = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="10" fill="#B0B8C1" />
      <path
        d="M39.982 59.366V66.758H41.452V59.366H39.982ZM38.26 61.998V63.174H40.794V61.998H38.26ZM32.758 67.346V71.994H41.452V67.346H32.758ZM39.996 68.508V70.818H34.186V68.508H39.996ZM32.394 60.08V61.088C32.394 62.474 31.932 64.238 30.462 65.148L31.26 66.198C32.94 65.176 33.444 62.964 33.444 61.088V60.08H32.394ZM32.744 60.08V61.088C32.744 62.838 33.094 65.232 34.816 66.338L35.516 65.246C34.074 64.238 33.78 62.404 33.78 61.088V60.08H32.744ZM35.852 60.08V61.088C35.852 62.446 35.558 64.266 34.116 65.246L34.816 66.338C36.524 65.246 36.888 62.894 36.888 61.088V60.08H35.852ZM36.174 60.08V61.088C36.174 63.076 36.678 65.204 38.358 66.198L39.156 65.148C37.686 64.28 37.238 62.586 37.238 61.088V60.08H36.174ZM44.0689 60.668V68.144H45.5249V60.668H44.0689ZM44.0689 67.752V68.998H44.9229C46.5049 68.998 47.9189 68.928 49.5289 68.606L49.3329 67.36C47.7789 67.682 46.4349 67.752 44.9229 67.752H44.0689ZM53.0429 59.366V72.162H54.4569V59.366H53.0429ZM47.6249 63.552V64.742H50.7049V63.552H47.6249ZM50.3409 59.632V71.546H51.7129V59.632H50.3409ZM59.9858 59.786C58.0678 59.786 56.6398 60.976 56.6398 62.67C56.6398 64.35 58.0678 65.54 59.9858 65.54C61.9178 65.54 63.3458 64.35 63.3458 62.67C63.3458 60.976 61.9178 59.786 59.9858 59.786ZM59.9858 60.99C61.1058 60.99 61.9178 61.648 61.9178 62.67C61.9178 63.692 61.1058 64.35 59.9858 64.35C58.8798 64.35 58.0538 63.692 58.0538 62.67C58.0538 61.648 58.8798 60.99 59.9858 60.99ZM65.4738 59.366V65.848H66.9438V59.366H65.4738ZM58.5578 66.436V67.584H65.4878V68.634H58.5858V71.476H60.0138V69.712H66.9438V66.436H58.5578ZM58.5858 70.846V72.008H67.3218V70.846H58.5858Z"
        fill="#F9FAFB"
      />
      <g clipPath="url(#clip0_117_88)">
        <path
          d="M59 50V36C59 34.9 58.1 34 57 34H43C41.9 34 41 34.9 41 36V50C41 51.1 41.9 52 43 52H57C58.1 52 59 51.1 59 50ZM46.5 44.5L49 47.51L52.5 43L57 49H43L46.5 44.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_117_88">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(38 31)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PostThumbnail;
