import React from "react";

const LikeIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6925 8.35625C17.3162 7.91 16.7737 7.65625 16.1875 7.65625H12.6612V5.25C12.6612 3.92 11.585 2.84375 10.185 2.84375C9.5725 2.84375 9.02125 3.21125 8.77625 3.78L6.55375 8.96875H4.9175C3.77125 8.96875 2.84375 9.89625 2.84375 11.0425V16.0912C2.84375 17.2287 3.78 18.1562 4.9175 18.1562H15.0325C15.9862 18.1562 16.8 17.4737 16.9662 16.5287L18.1212 9.96625C18.2175 9.38875 18.06 8.8025 17.6837 8.35625H17.6925ZM4.9175 16.8438C4.4975 16.8438 4.15625 16.5025 4.15625 16.0912V11.0425C4.15625 10.6225 4.4975 10.2812 4.9175 10.2812H6.32625V16.8438H4.9175ZM15.68 16.3012C15.6275 16.6162 15.3562 16.8438 15.0325 16.8438H7.6475V9.75625L9.98375 4.2875C10.0187 4.20875 10.0975 4.1475 10.2637 4.1475C10.8675 4.1475 11.3487 4.6375 11.3487 5.24125V8.96H16.1875C16.3887 8.96 16.5637 9.03875 16.6862 9.1875C16.8087 9.33625 16.8612 9.52875 16.8262 9.73L15.6712 16.2925L15.68 16.3012Z"
        fill="#6B7684"
      />
    </svg>
  );
};

export default LikeIcon;
